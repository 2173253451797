import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/SEO';
import Layout from '../layout';
import Footer from '../components/Footer/index';
import PageHeading from '../components/Support/pageHeading';
import SupportContact from '../components/Support';
import FaqList from '../components/FaqList';

const support = () => {
  const intl = useIntl();
  const supportFaqItems = [
    {
      question: 'What is DanfeBooks?',
      questionNe: 'डाँफेबुक्स के हो?',
      answer: {
        __html:
          'DanfeBooks is an online Hybrid HRM software combined with payroll with a premium service catering to Nepali businesses. <br /><br />A reliable and simplified service which imports employees as soon as you’ve created your account. Saving you from going to google translate or having to download plugins. DanfeBooks unique payroll system follows the Bikram Sambat (बिक्रम संवत) and Gregorian Calendar. <br /><br /> Allowing you to easily auto-generate payslips each Nepali month or Gregorian month on your day of choice. Thereby ensuring accurate tax and holiday calculations.',
      },
      answerNe: {
        __html:
          'डाँफेबुक्स नेपाल कै पहिलो अनलाइन Hybrid HRM  सफ्टवेयर हो जसमा मानव संशाधन व्यवस्थापनको साथै कर्मचारीहरुको हाजिरीको विवरण व्यवस्थित रुपमा संचय गर्न सकिन्छ।<br /><br />डाँफेबुक्स नेपाली पात्रो (बिक्रम संवत प्रणाली) मा केन्द्रित छ।  यस विशेषताले गर्दा कर्मचारीहरुको मासिक तलब नेपाली महिनामा आफैले उत्पादन गर्न सक्छ। <br /><br />यसको अर्को विशेषता, नेपाल सरकारले सरकारले जारी गरेको सार्बजनिक बिदा हरेक बर्ष अध्यावधिक हुन्।',
      },
      open: true,
    },
    {
      question: 'How does DanfeBooks protect my data?',
      questionNe:
        'डाँफेबुक्सले हाम्रो वा मेरो तथ्य/विवरणहरु कसरी सुरक्षित गर्छ?',
      answer: {
        __html:
          'None of the DanfeBooks team can see your company’s data, which include any financial figures or employee names. All of it is stored on Cloud servers and databases are encrypted. <br /><br />Similarly, to help further protection we use a periodic backup which at the worst looses 1 weeks worth of data, if not that, then nothing. We regularly and effectively carry out a software data audit each month a small operation which you won’t notice.',
      },
      answerNe: {
        __html:
          'डाँफेबुक्सले सारा तथ्य/विवरण, जानकारी, फाइल जस्ता सुचनाहरु विश्व प्रख्यात डाटा भण्डारण  गर्ने (क्लाउड) कम्पनीको सर्भरमा राख्ने गर्दछ।  यसरी भण्डारण गरिएका सुचना विवरणहरु गोप्य तरिकाले राखिएको हुन्छ। <br /><br />यसरी गोप्य तरिकाले राखिएको सुचना अन्य अनधिकृत समुहको हात लागेमा त्यो सुचनालाइ पढ्न नमिल्ने गरी राखिएको हुन्छ।<br /><br />साथै हामीले हप्तै पिच्छे सबै डाटाको ब्याकअप राख्ने गर्दछौ ।  कथम कदापि - दुर्घटना भैहाले तपाइको सुचना सुरक्षित रहन्छ । अझै राम्रो र दुरुस्त सेवाको लागि महिने पिच्छे हामी सुरक्षा प्रबिधिको अडिट र अनुगमन गर्ने गर्दछौ।',
      },
      open: false,
    },
    {
      question: 'What Customer Support Do DanfeBooks provide?',
      questionNe: 'डाँफेबुक्सले के-कस्ता ग्राहक सेवा  प्रदान गर्दछ?',
      answer: {
        __html:
          'If you need any help regarding using DanfeBooks, we are available from 9:00 am - 5:00 pm Nepal time. You can do this by requesting a ticket here, where we have a response time of 2 minutes. Likewise, you can chat with us or call us on +977-01-5245075.<br /><br />At your request, we offer software training and assist with your problems through a simple screen share. We are there to help.',
      },
      answerNe: {
        __html:
          'यदि तपाईंलाई डाँफेबुक्सको  प्रयोगका बारे कुनै सहयोग आवश्यक छ भने हामी नेपाल समय अनुसार बिहान ९ बजे देखि बेलुकी ५ बजेसम्म उपलब्ध छौं । तपाईं यहाँ टिकट लेखेर वा  अनुरोध गरेर यो गर्न सक्नुहुनेछ, जहाँ हामी २ मिनेट भित्रैमा तपाइको सहयोगको लागि उपलब्ध हुनेछौ । त्यस्तै गरी, तपाईं हामीसँग फोन न ०१-५२४५०७५ मा कुराकानी गर्न सक्नुहुनेछ। <br /> <br /> तपाईंको अनुरोधमा हामीले यो सफ्टवेयरबारेमा विशेष प्रशिक्षण पनि प्रदान गर्दछौं। हामी मद्दतका लागि तत्पर छौ। ',
      },
      open: false,
    },
  ];
  return (
    <Layout>
      <SEO
        meta={{
          title: `${intl.formatMessage({
            id: 'nav.support',
          })} | ${intl.formatMessage({ id: 'title' })}`,
          desc: `${intl.formatMessage({
            id: 'support.subHeading',
          })} ${intl.formatMessage({ id: 'support.contactDesc' })}`,
          slug: `/support`,
        }}
      />
      <PageHeading />
      <div className="site-wrap">
        <div className="site-container">
          <div className="site-content site-content-aside site-content-posts">
            <div className="articles">
              <section className="faq-wrap">
                <h2>{intl.formatMessage({ id: 'faq.title' })}</h2>
                <FaqList faqItems={supportFaqItems} />
              </section>
            </div>
            <SupportContact />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default support;
