import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function ContactUs() {
  const intl = useIntl();
  return (
    <aside>
      <h2>{intl.formatMessage({ id: 'support.contactus' })}:</h2>
      <p>{intl.formatMessage({ id: 'support.contactDesc' })}</p>
      <div className="card">
        <div className="card-block">
          <ul>
            <li>
              {intl.formatMessage({ id: 'support.phone' })}
              <a href="tel:+977-01-5245075" title="Call us now">
                {intl.formatMessage({ id: 'support.phoneNo.' })}
              </a>
            </li>
            <li>
              {intl.formatMessage({ id: 'support.email' })}
              <a href="mailto:support@danfebooks.com">support@danfebooks.com</a>
            </li>
          </ul>
        </div>
      </div>
      <p>
        <a
          rel="noopener noreferrer"
          href="https://danfebooks.freshdesk.com/support/tickets/new"
          target="_blank"
          className="masthead-buttons masthead-button-appreciate masthead-button-appreciate-i"
        >
          {intl.formatMessage({ id: 'support.createTicket' })}
        </a>
      </p>
    </aside>
  );
}

export default ContactUs;
