import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function PageHeading() {
  const intl = useIntl();
  return (
    <>
      <div className="masthead masthead-blog">
        <div className="masthead-contents">
          <div className="masthead-text">
            <h1 className="small">
              {intl.formatMessage({ id: 'support.24×7' })}{' '}
              <strong>{intl.formatMessage({ id: 'support.support' })}</strong>
            </h1>
            <p className="sub-lead">
              {intl.formatMessage({ id: 'support.subHeading' })}
            </p>
          </div>
        </div>
      </div>
      <div className="masthead-placeholder" />
    </>
  );
}
export default PageHeading;
